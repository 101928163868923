import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { tokens, routers } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js'
import door from './imgs/doorslow.gif'
import famgif from './imgs/famsmooth.gif'
import dustgif from './imgs/dust.gif'
import fam3 from './imgs/fam3.png'
import candleimg from './images/candle.png'

const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}

function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");

 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (!connected) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();


  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));

  var fam = new web3.eth.Contract(
    tokens["fam"]["abi"], 
    tokens["fam"]["address"]) 

  var candle = new web3.eth.Contract(
    tokens["candle"]["abi"], 
    tokens["candle"]["address"]) 

  var famx = new web3.eth.Contract(
    nfts["famx"]["abi"],
    nfts["famx"]["address"]
    )


  const letItRip = () => {
    console.log("let it rip")
    
    getActiveListings()
    getFamBal()
    getOwned()
    getCost()
    getValue()
    getListings()
  }




  const [activeListings, setActiveListings] = React.useState(0)
  const getActiveListings = () => {
    famx.methods.activeListings()
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setActiveListings(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

 

  const [listingIndex, setListingIndex] = React.useState(0)
  const nextListing = () => {
    if (listingIndex < activeListings) {
      setListingIndex(listingIndex + 1)
    }
  }
  const previousListing = () => {
    if (listingIndex > 1) {
      setListingIndex(listingIndex - 1)
    }
  }

  React.useEffect(()=>{
    if (listingIndex > 0) {
      getIDByIndex(listingIndex)
    }
  },[listingIndex])

  const [listedIDs, setListedIDs] = React.useState([]);
  const [listedImgs, setListedImgs] = React.useState([]);
  const getListings = () => {
    fam.methods.getOwned(nfts["famx"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setListedIDs(res)
        console.log(res)

        let imgs = res.map((n)=>{
          return "https://ipfs.io/ipfs/QmUtfucL7sXrfq7R1LKkpH9Z8N9jKbXpJrsPA4jUsDYKNv/" + n + ".png"
        })
        setListedImgs(imgs)
      })
  }



  const [id, setID] = React.useState(0)
  const getIDByIndex = () => {
    famx.methods.getIdByActiveListingIndex(listingIndex)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setID(res)
      })
  }

  React.useEffect(()=>{
    if (id > 0) {
      getPrice(id)
      fetchFam(id)
    }
  },[id])

  const [price, setPrice] = React.useState(0)

  const getPrice = () => {
    famx.methods.getPriceByID(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setPrice(res)
      })
  }



  const buy = () => {
    setIsPending(true)
    setPendingMessage("Buying Familiar #" + Number(id))
    let data = famx.methods.purchase(Number(id)).encodeABI()

    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["famx"]["address"],
          value: 0,
          data: data,
        },
      ],
    }).then((res)=>{
  
      setPendingMessage("Your purchase is complete!")
      setTimeout(()=>{
        setIsPending(false)
        setActiveSection(0)
      }, "4000")
    }).catch((err)=>{
      console.log("failed to buy", err)
      setIsPending(false)
    })

  }

  const approveCandle = (cb) => {
    setIsPending(true)
    setPendingMessage("Approving FamX to spend your $CANDLE")
    let data = candle.methods.approve(nfts["famx"]["address"], Number(price)).encodeABI()

    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: tokens["candle"]["address"],
          value: 0,
          data: data,

        },

      ],
    }).then((res)=>{
      setIsPending(false)
      cb()
    }).catch((err)=>{
      setIsPending(false)
    })
  }

  const approve = () => {
    setIsPending(true)
    setPendingMessage("Checking $CANDLE Allowance")
    candle.methods.allowance(window.ethereum.selectedAddress, nfts["famx"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        console.log(res)
        if (Number(fs(price)) > Number(fs(Number(res)))){
       
          approveCandle(buy)
        } else {
          console.log("need to buy")
          buy()
        } 
      }).catch((err)=>{
        console.log(err)
      })
  }

  const [famBal, setFamBal] = React.useState(0)

  const [famList, setFamList] = React.useState([])

  const getFamBal = () => {
    fam.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setFamBal(res)
        console.log(res)
      })
  }
 
  const getOwned = () => {
    fam.methods.getOwned(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setFamList(res)
        console.log(res)
      })
  }

  
  const listRef = React.createRef()
  const priceRef = React.createRef()

  const list = (l,p) => {
    setIsPending(true)
    setPendingMessage("Listing Familiar #" + l + " for " + fs(p) + " $CANDLE");
    let data = famx.methods.list(l, p).encodeABI()
    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["famx"]["address"],
          value: 0,
          data: data,
        },
      ],
    }).then((res)=>{
      setPendingMessage("Your listing is complete!")
      setTimeout(()=>{
        setIsPending(false)
        setActiveSection(0)
      }, "4000")
    }).catch((err)=>{
      setIsPending(false)
      console.log("failed to list", err)
    })
  }




  const approveFam = () => {
    setIsPending(true)

    console.log(listRef.current.value, priceRef.current.value)
    let listID = listRef.current.value
    setPendingMessage("Approving FamX to transfer familiar #" + listID)
    let priceDisplayAmt = priceRef.current.value
    let data = fam.methods.approve(nfts["famx"]["address"],listRef.current.value).encodeABI()
    
    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: tokens["fam"]["address"],
          value: 0,
          data: data,
        },
      ],
    }).then((res)=>{
      console.log("approval sent")
    }).then((res)=>{
      setTimeout( ()=> {
        list(listID, priceDisplayAmt + "000000000000000000")
      }, 2000)
      
    }).catch((err)=>{
      setIsPending(false)
      console.log("failed to approve", err)
    })
  }

  const relistRef = React.createRef()
  const repriceRef = React.createRef() 
  const delistRef = React.createRef()

  const relist = () => {
    setIsPending(true)
    let data = famx.methods.reList(relistRef.current.value, repriceRef.current.value + "000000000000000000").encodeABI()
    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["famx"]["address"],
          value: 0,
          data: data,
        },
      ],
    }).then((res)=>{
      console.log("relist sent")
    }).then((res)=>{
      setIsPending(false)
      console.log("relisted")

    }).catch((err)=>{
      setIsPending(false)
      console.log("failed to relist", err)
    })
  }

  const delist = () => {
    setIsPending(true)
    let data = famx.methods.deList(delistRef.current.value).encodeABI()
    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["famx"]["address"],
          value: 0,
          data: data,
        },
      ],
    }).then((res)=>{
      console.log("delist sent")
    }).then((res)=>{
      setIsPending(false)
      console.log("delisted")

    }).catch((err)=>{
      setIsPending(false)
      console.log("failed to delist", err)
    })
  }

  var brewer = new web3.eth.Contract(routers["brewer"]["abi"], routers["brewer"]["address"])

  const [candleCost, setCandleCost] = React.useState(0)
  const [famValue, setFamValue] = React.useState(0)

  const getCost = ()=>{
    brewer.methods.getAmountsIn("1000000000000000000",[tokens["candle"]["address"], tokens["fam"]["address"]])
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setCandleCost(res[0])
      }).catch((err)=>{
        console.log(err)
      })
  }

  const getValue = ()=>{
    brewer.methods.getAmountsOut("1000000000000000000",[tokens["fam"]["address"], tokens["candle"]["address"]])
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setFamValue(res[1])
      }).catch((err)=>{
        console.log(err)
      })
  }

  const approveCandleSpooky = (cb) => {
    setIsPending(true)
    setPendingMessage("Approving Spooky to spend your $CANDLE")
    let data = candle.methods.approve(routers["brewer"]["address"], Number(candleCost)).encodeABI()

    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: tokens["candle"]["address"],
          value: 0,
          data: data,

        },

      ],
    }).then((res)=>{
      setIsPending(false)
      cb()
    }).catch((err)=>{
      setIsPending(false)
    })
  }

  // spooky swap not working
  const swap = () => {
    setIsPending(true)
    setPendingMessage("market buying 1 $FAM")
    let data = brewer.methods.swapTokensForExactTokens(
      "1000000000000000000",
      Math.ceil(Number(fs(Number(candleCost)))+Number(fs(Number(candleCost)))/100) + "000000000000000000",
      [tokens["candle"]["address"],tokens["fam"]["address"]],
      window.ethereum.selectedAddress,
      Math.floor(Date.now() / 1000)
      ).encodeABI()
    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: routers["brewer"]["address"],
          value: 0,
          data: data
        }
      ],
    }).then((res)=>{
      setIsPending(false)


    }).catch((err)=>{
      setIsPending(false)
      console.log(err)
    })
  }

  const buyOnSpooky = ()=>{
    setIsPending(true)
    setPendingMessage("checking allowance")
    candle.methods.allowance(window.ethereum.selectedAddress, routers["brewer"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        console.log(res)
        if (Number(fs(candleCost)) > Number(fs(Number(res)))){
          console.log("need to approve")
          approveCandleSpooky(swap)
        } else {
          console.log("need to buy")
          swap()
        } 
      }).catch((err)=>{
        console.log(err)
      })
  

  

  }


const [famImg, setFamImg] = React.useState("")
const [famAttr, setFamAttr] = React.useState({})

   
const fetchFam = (id) => {

    let x = "https://ipfs.io/ipfs/QmUtfucL7sXrfq7R1LKkpH9Z8N9jKbXpJrsPA4jUsDYKNv/" + id + ".png"
    setFamImg(x)
  }


  
const [activeSection, setActiveSection] = React.useState(0);

const nav = (s) => {
  return ()=>{
    setActiveSection(s)
  }
}

const [listingState, setListingState] = React.useState(0);

const toListing = (index) => {
  return () => {
    setListingState(1)
    setListingIndex(index)
  }
}

const allListings = () => {
  setListingState(0)
}

const [pendingMessage, setPendingMessage] = React.useState("Confirm the Transaction")
const [listerOrStash, setListerOrStash] = React.useState(0);

const getStashPageIDs = (pageNumber) => {
  let pageSize = 8
  return famList.slice(pageSize*(pageNumber - 1),pageSize*(pageNumber - 1) + pageSize)
}

const [activeStashPage, setActiveStashPage] = React.useState(0)

const nextStashPage = () => {
  if (activeStashPage < Math.ceil(Number(fs(famBal))/8)) {
    setActiveStashPage(activeStashPage + 1)
  }
  
}
const previousStashPage = () => {
  if (activeStashPage > 1) {
    setActiveStashPage(activeStashPage - 1)
  }
}

const [activeStashItems, setActiveStashItems] = React.useState([])
React.useEffect(()=>{
  setActiveStashItems(getStashPageIDs(activeStashPage))
},[activeStashPage])

const clickItem = (item) => {
  return () => {
    setListerOrStash(1)
    listRef.current.value = Number(item)
  }
}

  return (
    <div className="App">
      
        
      
        <div className={"core core--" + connected}>
          <div className={"section core__nav s0--" + activeSection}>
            <h2>navigate</h2>
            <div className="nav" onClick={() => {
              setActiveSection(1)
              getActiveListings()
              getListings()
            }}>listings</div>
            <div className="nav" onClick={nav(2)}>list</div>
            <div className="nav" onClick={nav(3)}>relist</div>
            <div className="nav" onClick={nav(4)}>delist</div>
            <div className="nav" onClick={nav(5)}>buy</div>
          </div>
          <div className={"section core__listings s1--" + activeSection}>
            <h2 className="clicky" onClick={allListings}>listings</h2>
            
            <div className={"all-listings listing-state--" + listingState}>

          
              {
                listedImgs.map((src, i)=>(
                  <div key={"listing-img-" + i} className="listing-link" onClick={toListing(i + 1)}>
                    <img src={src} />
                  </div>
                  ))
              }
              <div className="page">{Number(activeListings)} Familiars listed</div>
            </div>
            <div className={"listing listing-state--" + listingState}>
              <div className="listing__index">Listing #{listingIndex}</div>
              <div className="listing__id">ID #{Number(id)}</div>
              <div className="listing__img"><img src={famImg} /></div>
              <div className="listing__price">Price = {fs(price)} <img className="token" src={candleimg} /></div>
            </div>

            <div className={"listing-nav listing-state--" + listingState}>
              <button onClick={previousListing}>{"<"}</button>
              <button class="middle-btn" onClick={approve}>Buy</button>
              <button onClick={nextListing}>{">"}</button>
            </div>

            
          </div>
          <div className={"section core__lister s2--" + activeSection}>
            <h2 className={"clicky listerOrStash-" + listerOrStash} onClick={()=>{setListerOrStash(0)}}>
            list 
              {
                ( listerOrStash == 0 ) ? "" : ( (listerOrStash == 1) ? " > List By ID" : " > Stash" ) 
              }
            </h2>
            <p>You have {fs(famBal)} Familiars</p>
            <p>1 $FAM = {fs(Number(famValue))} $CANDLE</p>
            <div className={"lister-nav lister-nav--" + listerOrStash}>
              <p>Either list using the ID# or search through your stash.</p>
              <button onClick={()=>{setListerOrStash(1)}}>List By ID #</button>
              <button onClick={()=>{
                setListerOrStash(2)
                setActiveStashPage(1)
              }}>Stash</button>
              
            </div>
            <div className={"lister lister--" + listerOrStash}>
              <p>List token ID# <input ref={listRef} placeholder="0"/></p>
              <p>For a price of <input ref={priceRef} placeholder="400"/>$CANDLE</p>
              <button onClick={approveFam}>List</button>
              <p className="hide">You own token IDs: {famList.map(x=><span>{Number(x)} </span>)}</p>
            </div>

            <div className={"stash stash--" + listerOrStash}>
              <div className="owned-familiars">

                {
                  activeStashItems.map((item, i)=>(
                    <div key={"item-"+i} className="item" onClick={clickItem(item)}>
                      <img src={
                        "https://ipfs.io/ipfs/QmUtfucL7sXrfq7R1LKkpH9Z8N9jKbXpJrsPA4jUsDYKNv/" + item + ".png"} />
                        <div className="info">#{Number(item)}</div>
                      </div>))
                }
              </div>
              <div className="stash-nav">
                <button onClick={previousStashPage}>{"<"}</button>
                <button onClick={nextStashPage}>{">"}</button>
              </div>
              <div className="page">Page {activeStashPage} of {Math.ceil(Number(fs(famBal))/8)}</div>
            </div>
          </div>

          <div className={"section core__relister s3--" + activeSection}>
            <h2>relist</h2>
            <p>You can change the price of your listed Familiars</p>
            <p>ReList token ID# <input ref={relistRef} placeholder="0"/></p>
            <p>For a price of <input ref={repriceRef} placeholder="400"/>$CANDLE</p>
            <button onClick={relist}>Relist</button>
          </div>

          <div className={"section core__delister s4--" + activeSection}>
            <h2>delist</h2>
            <p>You can delist your listed Familiars</p>
            <p>Delist token ID# <input ref={delistRef} placeholder="0"/></p>
            <button onClick={delist}>Delist</button>
          </div>

          <div className={"section core__spooky s5--" + activeSection}>
            <h2>buy</h2>
            <p>1 $FAM = {fs(Number(famValue))} $CANDLE</p>
            <p>visit spooky.fi</p>
            <button className="hide" onClick={buyOnSpooky}>Swap</button>

            
          </div>


        </div>
        <div className={"clicky header header--" + connected} onClick={nav(0)}>
          <div className="header__img"><img src={fam3} /></div>
          <div className="hamburger">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </div>
          <div className="header__text">FamX</div>

        </div>
        <div className={"pending pending--" + isPending}>
          <p>Transaction Pending</p>
          <img src={famgif} />
          <p>{pendingMessage}</p>

        </div>
        <button 
          disabled={isDisabled} 
          onClick={connectMM} 
          className={"mmbtn mmbtn--" + connected}>
          <img src={door} />
          <span>connect to famx</span>
        </button>

       
    </div>
  );
}

export default App;
